<template>
    <nav role="navigation" class="z-40 w-full animate-from-top" :class="{'fixed top-0': !editMode}">
        <div class="relative z-40 transition duration-500"
            :class="{
                'bg-gradient-to-b from-black/30 to-transparent text-white': styleColor === 'transWhite',
                'bg-gradient-to-b from-white/30 to-transparent text-black': styleColor === 'transBlack',
                'bg-black text-white': styleColor === 'black',
                'border-b border-gray-200': (styleColor === 'black' || styleColor === 'white') && !isOpen && isClosed,
                'bg-white text-black': styleColor === 'white',
                '-translate-y-full': !this.isFix}"
            :style="{
                transition: 'background-color 500ms ease, color 400ms ease, transform 200ms ease'
            }">
            <div class="container-wide flex lg:grid lg:grid-cols-12 lg:gap-6 xl:gap-10 transition-all duration-300 ease-in-out"
                :class="this.isFix && !this.isTop ? 'py-3' : 'py-5 lg:py-6'">
                <div class="lg:col-span-2 my-auto h-fit" :class="{'text-berry-700': isSophos && (styleColor === 'transBlack' || styleColor === 'white')}">
                    <slot :open="isOpen" name="logo" />
                </div>
                <div class="lg:col-span-10 w-full flex justify-end lg:gap-6 lg:justify-between">
                    <ul class="hidden my-auto relative group lg:flex gap-4 xl:gap-10">
                        <!-- Principal items at desktop -->
                        <li v-for="(mainItem, index) in siteMainItems"
                            :key="'desktop-level0-item-'+ index"
                            ref="menuDesktopItem"
                            @mouseenter="setUnderlinePosition(index)">
                            <template v-if="mainItem.items && mainItem.items.length > 0">
                                <button type="button"
                                    @click="toggleNavigation(0, index)"
                                    :aria-label="accessibility.nextLevel + ' ' + mainItem.label"
                                    class="whitespace-nowrap text-xl -m-3 p-3 berry-tab border border-transparent"
                                    :class="{'decoration-berry-600 underline underline-offset-8': itemOpen[0] === index}"
                                    tabindex="0">
                                    {{ mainItem.label }}
                                </button>
                            </template>
                            <template v-else>
                                <a :href="mainItem.link"
                                    :target="mainItem.target"
                                    :title="accessibility.item + ' '+ mainItem.label"
                                    :aria-label="accessibility.item + ' '+ mainItem.label"
                                    class="whitespace-nowrap text-xl">
                                    <span>{{ mainItem.label }}</span>
                                </a>
                            </template>
                        </li>
                        <div :style="underlineStyle" class="hidden group-hover:block h-[2px] bg-berry-600 absolute bottom-0 pointer-events-none transition-all duration-350"></div>
                    </ul>
                    <div class="flex justify-end w-full gap-6 lg:gap-4 xl:gap-8">
                        <!-- Search -->
                        <button type="button"
                            class="-m-2 p-2 berry-tab border-y-[2px] border-transparent my-auto group-hover:pt-[6px] hover:border-b-berry-600"
                            :aria-label="accessibility.searchSiteNav"
                            @click="toggleSearch"
                            tabindex="0">
                            <icon class="w-6 h-6 fill-current pointer-events-none lg:transition-colors lg:group-hover:text-black" name="search-outline" />
                        </button>
                        <!-- Burger mobile -->
                        <button type="button"
                            class="group -m-2 p-2 berry-tab lg:hidden my-auto"
                            @click="toggleNavigation(null, null)"
                            :aria-label="isOpen ? accessibility.closeNav : accessibility.openNav"
                            tabindex="0">
                            <icon :name="isOpen ? 'close' : 'menu'" class="w-[1.875rem] h-[1.875rem] fill-current pointer-events-none lg:mb-1 lg:transition-colors" />
                        </button>
                        <!-- Sites & Languages & contact desktop -->
                        <div class="hidden lg:flex lg:gap-4 xl:gap-8">
                            <div v-if="!isSophos" class="my-auto flex">
                                <button type="button"
                                    class="-m-2 p-2 group inline-flex my-auto items-center berry-tab cursor-pointer"
                                    @click="toggleSiteNav"
                                    :aria-label="accessibility.langNav"
                                    tabindex="0">
                                    <div class="-m-2 p-2 border-y-[2px] border-transparent group-hover:border-b-berry-600">
                                        <icon name="globe-2" class="shrink-0 w-6 h-6 fill-current lg:transition-colors" />
                                    </div>
                                    <span v-if="currentSiteName" class="pt-[2px] overflow-hidden shrink-0 transition-all duration-1000 whitespace-nowrap text-sm max-w-0 xl:group-hover:max-w-3xl  xl:group-hover:pl-2.5">{{ currentSiteName }}</span>
                                </button>
                            </div>
                            <template v-if="isTop || isOpen">
                                <navigation-languages :languages-nav-items='languagesNavItems' :is-sophos="isSophos"></navigation-languages>
                            </template>
                            <slot name="contact" :isOpen="isOpen" :styleColor="styleColor" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <collapse-transition :duration="500">
            <div v-if="isOpen" class="h-dvh w-full flex flex-col overflow-y-scroll lg:overflow-y-auto">
                <div class="grow bg-black mt-[-4.3rem] pt-[4.3rem] lg:mt-[-5.8rem] lg:pt-[5.8rem] flex flex-col lg:grow-0" :class="{'bg-black text-white': styleColor === 'black', 'bg-white text-black': styleColor === 'white'}">
                    <div v-if="!showSitesNav" class="flex flex-col grow h-full lg:h-auto">
                        <div class="w-full grow flex flex-col justify-between">
                            <div class="relative container-wide w-full flex flex-col py-10 h-full lg:grid lg:grid-cols-12 lg:gap-6 xl:gap-12">
                                <!-- level 0 desktop -->
                                <div v-if="itemOpen.length > 0" class="hidden lg:block lg:col-span-2 lg:py-0 lg:h-fit">
                                    <div class="w-full lg:text-left lg:block lg:text-3xl">
                                        {{ siteMainItems[itemOpen[0]].label }}
                                    </div>
                                </div>
                                <!-- level 0 mobile -->
                                <div class="flex flex-col justify-between h-full lg:col-span-2"
                                    :class="{'hidden': itemOpen.length > 0}">
                                    <ul>
                                        <li v-for="(mainItem, index) in siteMainItems" :key="'level0-item-'+ index">
                                            <template v-if="mainItem.items && mainItem.items.length > 0">
                                                <button type="button"
                                                    class="text-2xl w-full py-4 flex justify-between -mx-1 px-1 berry-tab border-b border-gray-800 lg:text-3xl"
                                                    @click="toggleNavigation(0, index)"
                                                    :aria-label="accessibility.nextLevel + ' ' + mainItem.label"
                                                    tabindex="0">
                                                    <span class="mt-[2px]">{{ mainItem.label }}</span>
                                                    <icon name="chevron-right" class="mt-px ml-1 w-7 h-7 fill-current" aria-hidden="true"/>
                                                </button>
                                            </template>
                                            <template v-else>
                                                <a :href="mainItem.link"
                                                    :target="mainItem.target"
                                                    :title="accessibility.item + ' '+ mainItem.label"
                                                    :aria-label="accessibility.item + ' '+ mainItem.label"
                                                    class="text-2xl py-2 lg:text-3xl">
                                                    <span class="mt-[2px]">{{ mainItem.label }}</span>
                                                </a>
                                            </template>
                                        </li>
                                    </ul>
                                    <div class="lg:hidden">
                                        <!-- Sites & Languages Mobile -->
                                        <div :class="isSophos ? 'pb-20 w-fit mx-auto' : 'mb-6 flex justify-between w-full'">
                                            <div v-if="!isSophos">
                                                <div class="inline-flex items-center cursor-pointer -m-2 p-2 berry-tab lg:hidden" @click="toggleSiteNav" tabindex="0">
                                                    <icon name="globe-2" class="mr-1.5 mb-0.5 w-4 h-4 fill-current" />
                                                    <span>{{ currentSiteName }}</span>
                                                    <span :class="{ 'rotate-180': showSitesNav }" class="transition-transform">
                                                        <icon name="chevron-down" class="mb-1 ml-1 w-7 h-7 fill-current" aria-hidden="true"/>
                                                    </span>
                                                </div>
                                            </div>
                                            <navigation-languages :languages-nav-items='languagesNavItems' :is-sophos="isSophos" :contrast='(styleColor === "transWhite" || styleColor === "black")'></navigation-languages>
                                        </div>
                                        <!-- Contact link mobile -->
                                        <div v-if="!isSophos" class="pb-20">
                                            <slot name="contact" :isOpen="isOpen" />
                                        </div>
                                    </div>
                                </div>
                                <!-- level 1 mobile/desktop -->
                                <div v-if="itemOpen.length> 0" class="lg:col-span-7">
                                    <!-- back to level 0 mobile -->
                                    <button type="button"
                                        :aria-label="accessibility.prevLevel + ' ' + siteMainItems[itemOpen[0]].label"
                                        class="w-full text-2xl pt-3 pb-4 -mx-1 px-1 berry-tab flex border-b border-berry-600 lg:hidden"
                                        :class="{'hidden': itemOpen.length != 1}"
                                        @click="toggleNavigation(0, itemOpen[0])"
                                        tabindex="0">
                                        <icon name="chevron-left" class="-ml-4 -mt-1 w-8 h-8 fill-current" aria-hidden="true"/>
                                        <span>{{ siteMainItems[itemOpen[0]].label }}</span>
                                    </button>
                                    <!-- level 1 items -->
                                    <div class="mt-4 lg:mt-0 lg:grid lg:grid-cols-3 lg:gap-3">
                                        <ul v-for="(secondaryItemArray, secIndex) in siteMainItems[itemOpen[0]].separatedItems"
                                            :key="'level1-items-'+ secIndex"
                                            class="pb-8 last:pb-14 lg:pb-16">
                                            <li v-for="secondaryItem in secondaryItemArray"
                                                :key="'level1-item-'+ secondaryItem.index">
                                                <template v-if="secondaryItem.type === 'separator'">
                                                    <div
                                                        class="text-gray-300/70 text-sm pt-4 pb-1.5 lg:mb-0 lg:py-3 berry-tab lg:block"
                                                        :class="{'hidden': itemOpen.length != 1, 'text-gray-300/70': styleColor === 'black', 'text-gray-600': styleColor === 'white', '-mt-12 lg:mt-0 lg:min-h-11': !secondaryItem.label}"
                                                        tabindex="0">
                                                        <span>{{ secondaryItem.label }}</span>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <!-- level 1 item-->
                                                    <a :href="secondaryItem.link"
                                                        :target="secondaryItem.target"
                                                        :title="accessibility.item + ' '+ secondaryItem.label"
                                                        :aria-label="accessibility.item + ' '+ secondaryItem.label"
                                                        class="block py-1.5 lg:my-0 lg:leading-[1.5rem] lg:text-lg lg:py-[0.35rem] lg:hover:text-berry-600"
                                                        :class="{'hidden lg:block': itemOpen.length != 1}">
                                                        <span>{{ secondaryItem.label }}</span>
                                                    </a>
                                                </template>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div v-if="itemOpen.length> 0" class="lg:col-span-3">
                                    <!-- teasers -->
                                    <ul class="pb-16 lg:pb-0">
                                        <li v-for="(teaser, teaserIndex) in siteMainItems[itemOpen[0]].teasers" :key="'teaser-'+itemOpen[0]+'-'+teaserIndex" class="mb-4">
                                            <a :href="teaser.link" :target="teaser.target"
                                                :title="teaser.label"
                                                :aria-label="teaser.label"
                                                class="block -mx-9 relative lg:mx-0 group grid grid-cols-3"
                                                :class="{'bg-gray-800': styleColor === 'black', 'bg-gray-100': styleColor === 'white'}">
                                                <div class="overflow-hidden">
                                                    <img :src="teaser.image" class="duration-300 ease-in-out group-hover:scale-105">
                                                </div>
                                                <div class="col-span-2 my-auto px-10 lg:px-7">
                                                    <div class="text-sm line-clamp-2 mb-1 text-gray-300/70">{{ teaser.kicker }}</div>
                                                    <div class="line-clamp-2 lg:text-lg">{{ teaser.title }}</div>
                                                </div>
                                                <icon name="chevron-right" class="hidden w-6 h-6 fill-current lg:group-hover:block absolute right-4 bottom-3" aria-hidden="true"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Sites navigation -->
                    <collapse-transition>
                        <slot v-if="showSitesNav" :showSitesNav="showSitesNav" :toggleSiteNav="toggleSiteNav" name="sitenav" />
                    </collapse-transition>
                    <collapse-transition>
                        <search-overlay v-if="showSearch"
                                        :all-results-label="search.allResultsLabel"
                                        :error-message="search.errorMessage"
                                        :no-results-message="search.noResultsMessage"
                                        :placeholder="search.placeholder"
                                        :results-label="search.resultsLabel"
                                        :search-link="search.searchLink" />
                    </collapse-transition>
                </div>
                <div class="hidden lg:block lg:grow bg-black/70"  @click="toggleNavigation(0, itemOpen[0])" @mouseenter="startMouseEnter" @mouseleave="stopMouseEnter"></div>
            </div>
        </collapse-transition>
    </nav>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch, Provide, Ref } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';
import Helper from '../../libs/Helper';
import SearchOverlay from '../SearchOverlay.vue';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import { getTwConfig } from '../utils';

interface SiteMainItem {
    link: string;
    label: string;
    target: string;
    index: string;
    type: string;
    ariaLabel: string;
    separatedItems: SiteMainItem[][];
    items: SiteMainItem[];
    teasers: { 'image': string; 'label': string; 'link': string; 'target': string; 'title': string; 'text': string; 'kicker': string }[];
    isOpen?: boolean;
}

@Component({
    components: { Icon, CollapseTransition, SearchOverlay }
})
class MainNavigation extends Vue {
    @Prop({ default: false }) editMode: boolean;
    @Prop({ default: false }) isSophos: boolean;
    @Prop({ required: true }) activeSite: string;
    @Prop({ required: true }) siteNavItems: { label: string; link: string; icon: string; locale: string }[];
    @Prop({ default: () => [] }) languagesNavItems: { active: boolean; label: string; link: string }[];
    @Prop({ required: true }) siteMainItems!: SiteMainItem[];
    @Prop({ required: true }) accessibility!: { navlogo: string; searchSiteNav: string; openNav: string; closeNav: string; langNav: string; nextLevel: string; prevLevel: string; item: string };
    @Prop({ required: true }) search!: { allResultsLabel: string; errorMessage: string; noResultsMessage: string; placeholder: string; resultsLabel: string; searchLink: string };
    @Prop({ default: 'transWhite' }) initialStyle!: string;
    @Provide('registerMainNavItem') childRegistration = this.register;
    @Ref('menuDesktopItem') menuDesktopItem: HTMLElement[];

    lastScrollPos= 0;
    isTop = true;
    isFix = true;
    isOpen = false;
    isClosed = true;
    showSearch = false;
    itemOpen = [];
    showSitesNav = false;
    mouseEnterTimeout = null;

    activeLocale = this.$lang.split('_')[0];
    twConfig = getTwConfig();
    underlineStyle= {
        width: '0px',
        transform: 'translateX(0px)'
    };

    get currentSiteName(): string {
        const activeSite = this.siteNavItems
            .map(x => ({
                title: x.label,
                site: x.link.replace(this.$contextPath, '').replace(/^\/?(\w*).*/, (_, x) => x),
                locale: x.locale
            }))
            .find(x => x.site === this.activeSite && x.locale === this.activeLocale);
        return activeSite ? activeSite.title : '';
    }

    get styleColor() {
        /* eslint-disable indent */
        return this.editMode ? 'white'
               : (this.isOpen && this.showSearch) ? 'transWhite'
               : (this.isOpen || !this.isClosed) ? 'black'
               : (!this.isTop) ? 'white'
               : this.initialStyle;
        /* eslint-enable indent */
    }

    mounted() {
        this.siteMainItems.forEach(item => {
            item.separatedItems = item.items ? Helper.navigationSeparators(item.items) : [];
        });
        document.addEventListener('scroll', this.onScroll, { passive: false });
    }

    // for normal navigation
    toggleNavigation(level = null, index = null) {
        clearTimeout(this.mouseEnterTimeout);
        if (this.editMode) {
            return;
        }

        // for mobile hamburger/close
        if (level === null && index === null) {
            this.itemOpen = [];
            this.showSitesNav = false;
            this.showSearch = false;
            this.isOpen = !this.isOpen;
            return;
        }

        const hasHigherLevels = this.itemOpen.slice(0, level).some(val => val !== undefined);
        if (this.itemOpen[level] === index) {
            this.itemOpen.splice(level, 1);
        } else {
            if (hasHigherLevels) {
                this.itemOpen = this.itemOpen.slice(0, level);
            }
            this.$set(this.itemOpen, level, index);
            this.itemOpen = this.itemOpen.slice(0, level + 1);
            this.isOpen = true;
        }
        const lgBreakpoint = parseInt(this.twConfig.theme.screens.lg, 10);
        const isDesktop = window.innerWidth >= lgBreakpoint;
        if (this.itemOpen.length === 0 && isDesktop) {
            this.isOpen = false;
        }
        this.showSitesNav = false;
        this.showSearch = false;
    }

    // for site navigation
    toggleSiteNav() {
        if (this.editMode) {
            return;
        }
        const lgBreakpoint = parseInt(this.twConfig.theme.screens.lg, 10);
        const isDesktop = window.innerWidth >= lgBreakpoint;
        this.showSitesNav = !this.showSitesNav;
        this.showSearch = false;
        this.isOpen = this.showSitesNav || (this.isOpen && !isDesktop);
        this.itemOpen = [];
    }

    toggleSearch() {
        this.showSearch = !this.showSearch;
        this.isOpen = this.showSearch;
        this.showSitesNav = false;
        this.itemOpen = [];
        document.body.classList.toggle('overflow-hidden', this.showSearch);
        document.body.classList.toggle('fixed', this.showSearch);
        document.body.classList.toggle('-z-1', this.showSearch);
    }

    // for underline at princiapl nav option item desktop
    setUnderlinePosition(index) {
        const menuItem = this.menuDesktopItem[index];
        if (menuItem) {
            const { offsetWidth, offsetLeft } = menuItem;
            this.underlineStyle.width = `${offsetWidth}px`;
            this.underlineStyle.transform = `translateX(${offsetLeft}px)`;
        }
    }

    private onScroll(event: Event) {
        if (this.editMode) {
            return;
        }

        if (this.isOpen) {
            event.preventDefault();
            return;
        }
        const scrollPos = Math.max(document.scrollingElement.scrollTop, 0);
        this.isTop = scrollPos <= 50;
        this.isFix = scrollPos <= this.lastScrollPos;
        this.lastScrollPos = scrollPos;
    }

    // for site navigation component
    private register(item) {
        item.$on('close', this.toggleSiteNav);
    }

    // for close navigation when mouse out after 2 seconds
    startMouseEnter() {
        this.mouseEnterTimeout = setTimeout(() => {
            this.toggleNavigation(0, this.itemOpen[0]);
        }, 1000);
    }

    stopMouseEnter() {
        clearTimeout(this.mouseEnterTimeout);
        this.mouseEnterTimeout = null;
    }

    beforeDestroy() {
        document.body.classList.remove('overflow-hidden');
        document.removeEventListener('scroll', this.onScroll);
    }

    @Watch('isOpen')
    onIsOpenChange(newVal: boolean) {
        document.body.classList.toggle('overflow-hidden', newVal);
        if (newVal === true) {
            this.isClosed = false;
        } else {
            setTimeout(() => { this.isClosed = true; }, 500);
        }
    }
}

export default Vue.component('main-navigation', MainNavigation);
</script>

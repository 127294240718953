<template>
    <div class="overflow-y-auto z-10 w-full h-full bg-black text-white">
        <div class="container-wide">
            <div class="pb-24 lg:pb-12 lg:grid lg:grid-cols-12 lg:gap-2 lg:pt-14">
                <button type="button"
                    :aria-label="accessibility.closeSiteNav"
                    class="z-10 w-full pt-11 pb-6 cursor-pointer -mx-1 px-1 berry-tab lg:hidden"
                    @click="$emit('close')"
                    tabindex="0">
                    <div class="w-full text-2xl -mx-1 px-1 pt-3 pb-4 berry-tab flex border-b border-berry-600">
                        <icon name="chevron-left" class="-ml-4 -mt-1 w-8 h-8 fill-current" aria-hidden="true"/>
                        {{ title }}
                    </div>
                </button>
                <div class="hidden lg:block lg:col-span-2 lg:py-0 lg:h-fit">
                    <div class="w-full lg:text-left lg:block lg:text-3xl">
                        {{ title }}
                    </div>
                </div>
                <div class="lg:col-span-3 lg:col-start-10">
                    <a  :href="global.link" class="block pt-6 pb-8 group font-semibold text-sm lg:py-4" :aria-labelledby="global.label">
                        <div class="transition-colors lg:grid lg:grid-cols-3">
                            <div class="hidden lg:flex lg:flex-col lg:bg-blue-900 lg:justify-center lg:aspect-square">
                                <div class="w-fit mx-auto">
                                    <div class="flex items-center w-fit mx-auto pb-2.5">
                                        <icon name="globe-2" class="w-4 h-4 fill-current" />
                                    </div>
                                    <div class="border-t border-current pt-2.5">
                                        {{ globalInfo.urlInfo }}
                                    </div>
                                </div>
                            </div>
                            <div class="lg:col-span-2 lg:bg-gray-800 lg:flex">
                                <div class="my-auto lg:mx-7">
                                    <div class="lg:text-base font-sans lg:uppercase font-medium">{{ global.label }}</div>
                                    <div class="hidden lg:block lg:text-sm font-normal">{{ globalInfo.text }}</div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="lg:col-span-7 lg:col-start-3 lg:grid lg:grid-cols-3 lg:row-start-1 lg:gap-2">
                    <div v-for="(countryGroup, index) in countries" :key="'site-'+ index" class="pb-9">
                        <ul v-for="country in countryGroup" :key="country.label">
                            <li v-if="country.type === 'separator'" class="pb-3 text-sm text-gray-300/70 lg:py-3">
                                {{ country.label }}
                            </li>
                            <li v-else :key="country.label">
                                <a :href="country.link"
                                    :aria-labelledby="country.label"
                                    class="flex items-center py-1.5 group lg:text-lg lg:py-1 lg:hover:opacity-100"
                                    :class="{ 'opacity-50': !((activeSite === country.site) && (country.locale === activeLocale)) }">
                                    <div class="flex overflow-hidden justify-center items-center mr-3 w-6 h-6 rounded-full border border-white transition-all lg:w-5 lg:h-5 lg:-ml-13">
                                        <icon :name="country.icon" class="w-full h-full rounded-full" />
                                    </div>
                                    <div class="transition-color">
                                        {{ country.label }}
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';
import Helper from '../../libs/Helper';

@Component({
    components: { Icon }
})
class SiteNavigation extends Vue {
    @Prop({ required: true }) title: string;
    @Prop({ required: true }) activeSite: string;
    @Prop({ required: true }) navItems: { label: string; link: string; icon: string; locale: string; type: string }[];
    @Prop({ required: true }) accessibility: { closeSiteNav: string };
    @Prop({ required: true }) globalInfo: { text: string; urlInfo: string };

    // get only the local, remove the country
    activeLocale = this.$lang.split('_')[0];
    separatedNavItems = [];

    get countries() {
        return this.separatedNavItems.map(group =>
            group.map(item => {
                const site = item.link.replace(this.$contextPath, '').replace(/^\/?(\w*).*/, (_, x) => x);
                return { ...item, site };
            })
        );
    }

    get global() {
        if (this.countries.length) {
            return { ...this.navItems.find(x => !!x.link.match(/\/int($|\/)/)), site: 'int' };
        }
        return null;
    }

    get activeSiteName(): string {
        const item = this.navItems
            .map(x => ({ ...x, site: x.link.replace(this.$contextPath, '').replace(/^\/?(\w*).*/, (_, x) => x) }))
            .find(x => x.site === this.activeSite);
        return item ? item.label : '';
    }

    mounted() {
        const navItemsWithoutGlobal = this.navItems.filter(item => !item.link.match(/\/int($|\/)/));
        this.separatedNavItems = Helper.navigationSeparators(navItemsWithoutGlobal);
    }
}

export default Vue.component('site-navigation', SiteNavigation);

</script>

<template>
    <div v-if="languagesNavItems.length > 1" class="flex my-auto gap-2" :class="{'max-w-28 overflow-x-scroll overflow-y-hidden': languagesNavItems.length > 3}">
        <template v-for="(lang, i) in languagesNavItems">
            <div v-if="i" :key="`sep${i}`" class="opacity-80">|</div>
            <template v-if="lang.active">
                <div :key="`${lang.link}-m`">
                    <template v-if="!isSophos">
                        {{ lang.label.split('_')[0] }}
                    </template>
                    <template v-else>
                        <icon :name="lang.label.split('_').pop().toLowerCase()"
                            class="w-6 h-6 fill-current rounded-full border border-white" />
                    </template>
                </div>
            </template>
            <template v-else>
                <a :href="lang.link"
                   :aria-label="lang.label.split('_')[0]"
                   :key="`${lang.link}-m`"
                   class="group -m-1 p-1 berry-tab opacity-80 hover:opacity-100">
                   <template v-if="!isSophos">
                        {{ lang.label.split('_')[0] }}
                   </template>
                   <template v-else>
                        <icon :name="lang.label.split('_').pop().toLowerCase()"
                            class="w-6 h-6 fill-current rounded-full border border-white opacity-90 group-hover:opacity-100" />
                   </template>
                </a>
            </template>
        </template>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';

@Component({
    components: { Icon }
})
class NavigationLanguages extends Vue {
    @Prop({ default: 'false' }) isSophos: boolean;
    @Prop({ default: () => [] }) languagesNavItems: { active: boolean; label: string; link: string }[];
}

export default Vue.component('navigation-languages', NavigationLanguages);
</script>
